<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Cài đặt mẫu hợp đồng</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="8"
        >
          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="apartment"
                    rules="required"
                  >
                    <select-apartment
                      v-model="apartment"
                      required
                      :default-value="apartment"
                      label="Tòa nhà"
                      :state="getValidationState(validationContext)"
                      :error="validationContext.errors[0]"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" />
              </b-row>
              <b-row v-if="apartment">
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="contractTemplate"
                    rules="required"
                  >
                    <b-form-group>
                      <template v-slot:label>
                        Mẫu hợp đồng <span class="text-danger">(*)</span>
                      </template>
                      <editor
                        v-model="contractTemplate"
                        :default-value="contractTemplate"
                        class="border"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    class="mt-2"
                    variant="primary"
                    block
                    type="submit"
                    :disabled="invalid"
                  >
                    Lưu
                  </b-button>
                </b-col>

              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <attribute-table :params="params" />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BButton, BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import Editor from '@/views/components/Editor.vue';
import AttributeTable from '../AttributeTable.vue';
import useContractTemplate from './useContractTemplate';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,

    SelectApartment,
    AttributeTable,
    Editor,
  },
  data() {
    return {
      // validation
      required,
    };
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      apartment, contractTemplate, onSubmit, fetchData, params,
    } = useContractTemplate();

    return {

      // Form Validation
      resetForm,
      clearForm,
      params,
      refFormObserver,
      getValidationState,
      apartment,
      contractTemplate,
      onSubmit,
      fetchData,
    };
  },

  created() {
    this.fetchData();
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
